import { Entity } from "../Entity";
import { CheckBaseEntity } from "./check/CheckBaseEntity";

export class SectionEntity extends Entity {
  private readonly _title: string;

  public checks: CheckBaseEntity[];

  protected constructor(uid: string, title: string, checks: CheckBaseEntity[]) {
    super(uid);

    this._title = title;
    this.checks = checks;
  }

  public get title(): string {
    return this._title;
  }

  public static new(
    sectionTitle: string,
    checks: CheckBaseEntity[],
  ): SectionEntity {
    return new SectionEntity("non-existing", sectionTitle, checks);
  }

  public static existing(
    uid: string,
    sectionTitle: string,
    checks: CheckBaseEntity[],
  ): SectionEntity {
    return new SectionEntity(uid, sectionTitle, checks);
  }
}
