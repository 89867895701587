import { IContentResult } from "../../../core/results/Result";
import { SuccessfulContentResult } from "../../../core/results/successful/SuccessfulResult";

export async function getToken(): Promise<IContentResult<string>> {
  //@ts-ignore
  const token = await window.Clerk.session.getToken({
    template: "jwt-template-1",
  });

  return new SuccessfulContentResult(token);
}
