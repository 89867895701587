import { reaction } from "mobx";
import { useEffect, useState } from "react";

export const createObservableStateHook =
  <T>(getValue: () => T) =>
  (): T => {
    const [state, setState] = useState<T>(getValue);

    useEffect(
      () =>
        reaction(
          () => getValue(),
          (value) => {
            setState(value);
          },
          { fireImmediately: true },
        ),
      [],
    );

    return state;
  };
