import React, { createContext, PropsWithChildren, useEffect } from "react";
import { IChecklistViewModel } from "../../../application/viewModels/ChecklistViewModel";
import { useChecklist } from "../../hooks/useChecklist";

interface IChecklistManagerState {
  checklist: IChecklistViewModel;
}

interface IChecklistContextProps {
  checklist: IChecklistViewModel;
}

export const ChecklistContext = createContext<IChecklistManagerState>({
  checklist: {} as IChecklistViewModel,
});

export const ChecklistManager: React.FC<
  PropsWithChildren<IChecklistContextProps>
> = ({ checklist, children }) => {
  const c = useChecklist(checklist);

  useEffect(() => {
    return () => {
      c.leaveChecklist();
    };
  }, [checklist]);

  return (
    <ChecklistContext.Provider value={{ checklist: c }}>
      {children}
    </ChecklistContext.Provider>
  );
};
