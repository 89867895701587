import { useAuth } from "@clerk/clerk-react";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { CheckedState } from "@radix-ui/react-checkbox";
import { format } from "date-fns";
import { Bot, Loader2, Radio, RefreshCcw } from "lucide-react";
import { useEffect, useState } from "react";
import { iotHooks } from "../../../api";
import { IChecklistViewModel } from "../../../application/viewModels/ChecklistViewModel";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../components/ui/dialog";
import { useProjectStore } from "../../../stores/project-store";
import { Button } from "../../ui/button";
import { Checkbox } from "../../ui/checkbox";

type IoTMessage = {
  createdAtUtc: string;
  message: string;
  id: string;
};

export default function IoTPopup({
  checklist,
  checklistId,
  groupId,
}: {
  checklist: IChecklistViewModel;
  checklistId: number;
  groupId: string;
}) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedDeviceId, setSelectedDeviceId] = useState<number>();
  const { selectedProject } = useProjectStore();
  const [platen, setPlaten] = useState<string[]>([]);

  const { data: devices } = iotHooks.useGet(
    "/v2/iot-devices",
    {
      queries: {
        page: 1,
        size: 25,
      },
    },
    {
      enabled: true,
    },
  );

  const { getToken } = useAuth();
  const { mutate: startSelfContainedQuery } = iotHooks.usePost(
    "/v2/iot-devices/start-self-contained-query",
  );
  const { mutate: startSelfContained } = iotHooks.usePost(
    "/v2/iot-devices/start-self-contained",
  );

  const [loadingPlaten, setLoadingPlaten] = useState(false);

  async function HandleJoinIoTChecklistQuery() {
    let connection: HubConnection | undefined = undefined;
    const token = await getToken({ template: "jwt-template-1" });

    connection = new HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_API_URL}/hubs/iot`, {
        accessTokenFactory: () => token ?? "",
      })
      .withAutomaticReconnect()
      .build();

    await connection.start();

    await connection.invoke(
      "JoinIoTChecklistQuery",
      `${checklistId}`,
      "GetRSFileNamesByProjectCode",
    );

    connection.on("AddQueryResults", (data: any) => {
      //...
      setPlaten(data.results);

      setLoadingPlaten(false);

      console.log("AddQueryResults", { data });
    });
  }

  const [selectedPlaten, setSelectedPlaten] = useState<string[]>([]);

  function handlePrint() {
    //...
    startSelfContained(
      {
        deviceId: selectedDeviceId,
        checklistId: checklistId,
        payload: [
          {
            key: "ProjectCode",
            value: `${selectedProject?.code}`,
          },
          {
            key: "Aantalplaten",
            value: `${selectedPlaten.length}`,
          },
          {
            key: "IsResend",
            value: "true",
          },

          ...selectedPlaten.map((plaat) => ({
            key: "File",
            value: plaat,
          })),
        ],
      },
      {
        onSuccess: () => {
          alert("Platen worden geprint.");
          setDialogOpen(false);
          setSelectedPlaten([]);
          setSelectedDeviceId(undefined);
        },
      },
    );
  }

  function handleRefetchDevice() {
    if (!selectedDeviceId) return;

    startSelfContainedQuery(
      {
        deviceId: selectedDeviceId,
        checklistId: checklistId,
        payload: [
          {
            key: "QueryName",
            value: "GetRSFileNamesByProjectCode",
          },
          {
            key: "ProjectCode",
            value: `${selectedProject?.code}`,
          },
        ],
      },
      {
        onSuccess: () => {
          //...
        },
      },
    );
  }

  return (
    <Dialog
      open={dialogOpen}
      onOpenChange={(change) => {
        setDialogOpen(change);

        if (!change) {
          setSelectedDeviceId(undefined);
        }

        if (change) {
          HandleJoinIoTChecklistQuery();
        }
      }}
    >
      <DialogTrigger className="w-full">
        <div
          onClick={(e) => {
            // TODO: Open a popup...
          }}
          className="appearance-none h-12 pr-4 pl-4 py-3.5 flex items-center w-full sm:dark:hover:bg-[#323238]/30 rounded-lg"
        >
          <div className="flex items-center">
            <div className="mr-3">
              <Bot className="w-4 h-4 1animate-[spin_7s_linear_infinite] text-muted-foreground" />
            </div>
            <span className="leading-none block text-sm font-semibold truncate sm:pr-8 sm:w-full overflow-hidden text-primary">
              {checklist.specialFunctions.split(":")[1]}
            </span>
          </div>
        </div>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[625px]">
        <DialogHeader>
          <DialogTitle>{checklist.specialFunctions.split(":")[1]}</DialogTitle>
        </DialogHeader>
        <div className="w-full">
          {devices?.results?.map((device: any) => (
            <div
              onClick={() => {
                setSelectedDeviceId((v) =>
                  device.id === v ? undefined : device.id,
                );

                setLoadingPlaten(true);

                startSelfContainedQuery(
                  {
                    deviceId: device.id,
                    checklistId: checklistId,
                    payload: [
                      {
                        key: "QueryName",
                        value: "GetRSFileNamesByProjectCode",
                      },
                      {
                        key: "ProjectCode",
                        value: `${selectedProject?.code}`,
                      },
                    ],
                  },
                  {
                    onSuccess: () => {
                      //...
                    },
                  },
                );
              }}
              className="border bg-background font-medium rounded-md p-2 cursor-pointer text-sm mb-1.5 flex items-center select-none"
              key={device.id}
            >
              <Radio
                className={`w-4 h-4 mr-1.5 ${
                  selectedDeviceId === device.id
                    ? "text-green-400"
                    : "text-muted-foreground"
                }`}
              />
              {device.name}
            </div>
          ))}

          <div className="border rounded-md overflow-hidden">
            <div className="h-8 border-b flex items-center justify-between px-2 bg-secondary">
              <span className="text-sm text-muted-foreground">CTP Platen</span>
              <div
                className="cursor-pointer hover:text-white text-muted-foreground"
                onClick={handleRefetchDevice}
              >
                <RefreshCcw className="w-4 h-4 " />
              </div>
            </div>
            {loadingPlaten && (
              <div className="h-20 flex items-center justify-center flex-col">
                <Loader2 className="w-4 h-4 animate-spin text-muted-foreground" />
                <span className="text-sm text-muted-foreground mt-1.5">
                  Platen worden opgehaald...
                </span>
              </div>
            )}

            {
              !loadingPlaten && platen.length > 0 && (
                <div className="p-2 h-20 overflow-auto flex flex-col gap-y-2">
                  {platen.map((plaat) => (
                    <FileName
                      key={plaat}
                      fileName={plaat}
                      onClick={(val) => {
                        if (!val) {
                          setSelectedPlaten((v) => [
                            ...v.filter((x) => x !== plaat),
                          ]);
                        } else {
                          setSelectedPlaten((v) => [...v, plaat]);
                        }
                      }}
                    />
                  ))}
                </div>
              )
              // <div className="p-2 h-20 overflow-auto">
              //   {platen.map((plaat, idx) => (
              //     <p
              //       className="text-sm text-muted-foreground"
              //       key={`${plaat}-${idx}`}
              //     >
              //       {plaat}
              //     </p>
              //   ))}
              // </div>
            }

            {!loadingPlaten && platen.length === 0 && (
              <div className="h-20 flex items-center justify-center">
                <span className="text-sm text-muted-foreground">
                  Selecteer een apparaat
                </span>
              </div>
            )}
          </div>

          <Button
            disabled={
              selectedPlaten.length === 0 || selectedDeviceId === undefined
            }
            className="w-full mt-2"
            onClick={handlePrint}
          >
            Selectie printen
          </Button>
        </div>
        <DialogFooter></DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

function MessageBox({
  selectedDeviceId,
  checklistId,
  groupId,
}: {
  selectedDeviceId: number | undefined;
  checklistId: number;
  groupId: string;
}) {
  const [messages, setMessages] = useState<IoTMessage[]>([]);

  const { data: iotActivities } = iotHooks.useGet(
    "/v2/iot-activities/:deviceId",
    {
      queries: {
        page: 1,
        size: 25,
      },
      params: {
        deviceId: selectedDeviceId ?? 0,
      },
    },
    {
      enabled: selectedDeviceId !== undefined,
    },
  );

  useEffect(() => {
    if (iotActivities && iotActivities) {
      const mes = iotActivities?.results?.map((m) => {
        return {
          message: m.message ?? "",
          createdAtUtc: m.createdAtUtc ?? "",
          id: `${m.id}`,
        };
      });

      if (mes) {
        // setMessages(mes);
      }
    }

    if (!iotActivities) {
      setMessages([]);
    }
  }, [iotActivities, selectedDeviceId]);

  return (
    <>
      <div className="border hidden rounded-md bg-background h-52 relative overflow-y-scroll">
        <div className="p-2">
          {messages
            .sort(
              (a, b) =>
                new Date(a.createdAtUtc).getTime() -
                new Date(b.createdAtUtc).getTime(),
            )
            ?.map((m) => (
              <div
                className="text-sm font-mono text-muted-foreground grid grid-cols-[auto_1fr]"
                key={`${m.id}`}
              >
                <p className="uppercase">
                  {/* {format(new Date(m.createdAtUtc), "MMM dd HH:mm:ss")}:{" "} */}
                  {format(new Date(m.createdAtUtc), "HH:mm:ss")}:{" "}
                </p>
                <p className="pl-1 text-primary">{m.message}</p>
              </div>
            ))}
        </div>
      </div>

      <CTPReprintButton
        selectedDeviceId={selectedDeviceId}
        checklistId={checklistId}
        groupId={groupId}
        onUpdate={(data) => {
          setMessages((v) => {
            return [
              ...v.filter((x) => x.id !== data.id),
              {
                message: data.message,
                createdAtUtc: data.createdAtUtc,
                id: data.id,
              },
            ];
          });
        }}
      />
    </>
  );
}

function CTPReprintButton({
  selectedDeviceId,
  checklistId,
  groupId,
  onUpdate,
}: {
  selectedDeviceId: number | undefined;
  checklistId: number;
  groupId: string;
  onUpdate: (data: IoTMessage) => void;
}) {
  const { mutate: startDevicePost } = iotHooks.usePost("/v2/iot-devices/start");
  const { getToken } = useAuth();
  const [selectedColors, setSelectedColors] = useState<string[]>([]);

  async function startDevice() {
    if (!selectedDeviceId) {
      return;
    }

    //...
    startDevicePost({
      deviceId: selectedDeviceId,
      // checklistId: checklistId,
      // groupId: groupId,
      // checkId: undefined,
      // checkType: undefined,
      payload: [
        {
          key: "ProjectCode",
          value: "code",
        },
        {
          key: "Aantalplaten",
          value: "0",
        },
        {
          key: "IsResend",
          value: "false",
        },
        {
          key: "File",
          value: "File1",
        },
        {
          key: "File",
          value: "File2",
        },
      ],
    });
  }

  useEffect(() => {
    if (selectedDeviceId) {
      (async () => {
        let connection: HubConnection | undefined = undefined;
        const token = await getToken({ template: "jwt-template-1" });

        connection = new HubConnectionBuilder()
          .withUrl(`${process.env.REACT_APP_API_URL}/hubs/iot`, {
            accessTokenFactory: () => token ?? "",
          })
          .withAutomaticReconnect()
          .build();

        await connection.start();

        await connection.invoke("JoinDevice", `${selectedDeviceId}`);

        connection.on("AddIoTActivity", (data: IoTMessage) => {
          console.log("SIGNALR SERVICE CALLBACK", data);
          onUpdate(data);
        });
      })();
    }
  }, [selectedDeviceId]);

  return (
    <>
      <div className="border rounded-md bg-background relative p-3 mt-2 space-y-3">
        <div>
          Platen:{" "}
          {selectedColors.map((s) => (
            <p key={s}>{s}</p>
          ))}
        </div>

        {/* <FileName fileName="Plaat 1" /> */}
      </div>

      <div>
        <Button
          disabled={!selectedDeviceId}
          className="border px-2 py-1.5 rounded-md text-sm w-full mt-2 bg-white text-black"
          onClick={startDevice}
        >
          Selectie platen herprinten
        </Button>
      </div>
    </>
  );
}

function FileName({
  fileName,
  onClick,
}: {
  fileName: string;
  onClick: (val: CheckedState) => void;
}) {
  return (
    <div className="flex items-center space-x-2">
      <Checkbox
        id={fileName}
        onCheckedChange={(val) => {
          onClick(val);

          if (!val) {
            // setSelectedColors((v) => [...v.filter((x) => x !== "Plaat 3")]);
          } else {
            // setSelectedColors((v) => [...v, "Plaat 3"]);
          }
        }}
      />
      <label
        htmlFor={fileName}
        className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
      >
        {fileName}
      </label>
    </div>
  );
}
