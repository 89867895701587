import { IUnsuccessfulContentResult } from "../../../core/results/Result";
import { UnsuccessfulContentResult } from "../../../core/results/unsuccessful/UnsuccessfulResult";
import { FailureReasonMapper } from "../mappers/FailureReasonMapper";
import { ErrorDetails, ResponseBase } from "./types";

interface IHeaders {
  authorization: string;
  "CL-USER-ID": string;
}

const x = { state: { selectedUser: { id: "" } } };

export abstract class EndpointMethod {
  private readonly _path: string;
  private _headers: IHeaders | undefined;

  constructor(path: string) {
    this._path = path;
  }

  protected get path(): string {
    return this._path;
  }

  protected get headers(): IHeaders | undefined {
    const userId =
      //@ts-ignore
      window.Clerk.user.id === process.env.REACT_APP_ADMIN_USER_ID
        ? JSON.parse(localStorage.getItem("user-storage") || JSON.stringify(x))
            .state.loggedInUser?.userId
        : //@ts-ignore
          window.Clerk.user.id;

    if (this._headers === undefined) return undefined;

    return { ...this._headers, "CL-USER-ID": userId };
  }

  protected abstract execute(): Promise<unknown>;

  public authenticate(token: string): this {
    const userId = //@ts-ignore
      window.Clerk.user.id === process.env.REACT_APP_ADMIN_USER_ID
        ? JSON.parse(localStorage.getItem("user-storage") || JSON.stringify(x))
            .state.loggedInUser?.userId
        : //@ts-ignore
          window.Clerk.user.id;

    this._headers = {
      ...this._headers,
      authorization: `Bearer ${token}`,
      "CL-USER-ID": userId,
    };

    return this;
  }

  protected mapToUnsuccessfulResult<T>(
    response: ResponseBase<T>,
  ): IUnsuccessfulContentResult<T> {
    if (response.success)
      throw new Error("Cannot map successful response to unsuccessful result!");

    if (!response.error)
      throw new Error("Cannot map unsuccessful result without an error!");

    const error: ErrorDetails = response.error;
    return new UnsuccessfulContentResult<T>({
      code: error.code,
      message: error.message,
      failureReason: FailureReasonMapper.fromErrorReason(error.reason),
    });
  }
}
