import { useUser } from "@clerk/clerk-react";
import { format } from "date-fns";
import { useState } from "react";
import { ICheckInputSingleLineViewModel } from "../../application/viewModels/CheckInputSingleLineViewModel";

export default function useZebraLabelPrint() {
  const { user } = useUser();
  const [allowPrint, setAllowPrint] = useState(true);

  const print = (zebraPrinter: any, serial: string) => {
    // alert(JSON.stringify(zebraPrinter));

    const zpl = `
    ^XA
^CF0,40
^FO50,300^FDMichel Vissia^FS
^FO50,340^FDOrder: 313103^FS
^FO50,380^FDTijd: 07-11-2024 16:02^FS
^FO50,420^FDKlant: Checklab^FS
^XZ
    `;

    // const zpl = `^XA
    //               ^BY2,2,100
    //               ^FO20,20^BC^FD${serial}^FS
    //               ^XZ`;

    return new Promise((resolve, reject) => {
      zebraPrinter.send(
        zpl,
        () => {
          console.log("Printed succesfully.");
          resolve(true);
        },
        () => {
          console.log("Something went wrong trying to print.");
          setAllowPrint(true);
          reject();
        },
      );
    });
  };

  async function printLabel() {
    //TODO: print using Zebra printer...
    return new Promise((resolve, reject) => {
      //@ts-ignore
      window.BrowserPrint.getDefaultDevice(
        "printer",
        (device: any) => {
          try {
            print(device, "123");
            resolve(true);
          } catch (e: unknown) {
            alert(
              "Er is iets fout gegaan met het printen probeer het opnieuw. (printLabel)",
            );
            setAllowPrint(true);
            reject();
          }
        },
        (error: any) => {
          console.log(error);
          setAllowPrint(true);
          reject();
        },
      );
    });
  }

  async function handlePrint(
    approved: boolean,
    check: ICheckInputSingleLineViewModel,
  ) {
    if (!allowPrint) {
      alert("Wacht 10 seconden voor de volgende print actie.");

      return;
    }

    try {
      setAllowPrint(false);

      setTimeout(() => {
        setAllowPrint(true);
      }, 10000);

      await printLabel();

      //TODO: update check text and append new entry after printing the label succesfully.
      const entry = `${approved ? "approved;" : "not-approved;"} ${
        user?.fullName
      }; ${format(new Date(), "MM-dd-yyyy HH:mm:ss")}`;

      const previousEntries = check.text;

      await check.store(
        `${previousEntries ? previousEntries + "|" : ""}${entry}`,
      );
    } catch (e: unknown) {
      // setAllowPrint(true);
      alert(
        "Er is iets fout gegaan met het printen probeer het opnieuw. (handlePrint)",
      );
    }
  }

  return { handlePrint, allowPrint };
}
