import {
  ArrowLeft,
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
  Locate,
} from "lucide-react";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useTheme } from "styled-components";
import { z } from "zod";
import { schemas } from "../../../api/schema";
import { ICheckBaseViewModel } from "../../../application/viewModels/CheckBaseViewModel";
import { ICheckChecklistViewModel } from "../../../application/viewModels/CheckChecklistViewModel";
import { ICheckInputNumberViewModel } from "../../../application/viewModels/CheckInputNumberViewModel";
import { ICheckInputSingleLineViewModel } from "../../../application/viewModels/CheckInputSingleLineViewModel";
import { ICheckOkNotOkViewModel } from "../../../application/viewModels/CheckOkNotOkViewModel";
import { ICheckPhotoViewModel } from "../../../application/viewModels/CheckPhotoViewModel";
import { ICheckScanViewModel } from "../../../application/viewModels/CheckScanViewModel";
import { ICheckSignatureViewModel } from "../../../application/viewModels/CheckSignatureViewModel";
import { ICheckSingleOptionViewModel } from "../../../application/viewModels/CheckSingleOptionViewModel";
import { ICheckYesNoViewModel } from "../../../application/viewModels/CheckYesNoViewModel";
import { CheckTypes } from "../../../core/domain/entities/checklist/check/CheckBaseEntity";
import { OperatorRoutePath } from "../../../data";
import { ChecklistContext } from "../../context/ChecklistManager";
import { SettingsContext } from "../../context/SettingsManager";
import useMediaQuery from "../../hooks/useMediaQuery";
import PageLayout from "../../molecules/PageLayout";
import { CheckPopupChecklist } from "../../organisms/CheckPopup/CheckPopupChecklist";
import { CheckPopupInputNumber } from "../../organisms/CheckPopup/CheckPopupInputNumber";
import { CheckPopupInputSingleLine } from "../../organisms/CheckPopup/CheckPopupInputSingleLine";
import { CheckPopupInputSingleLinePrint } from "../../organisms/CheckPopup/CheckPopupInputSingleLinePrint";
import { CheckPopupOkNotOk } from "../../organisms/CheckPopup/CheckPopupOkNotOk";
import { CheckPopupPhoto } from "../../organisms/CheckPopup/CheckPopupPhoto";
import { CheckPopupScan } from "../../organisms/CheckPopup/CheckPopupScan";
import { CheckPopupSignature } from "../../organisms/CheckPopup/CheckPopupSignature";
import { CheckPopupSingleOption } from "../../organisms/CheckPopup/CheckPopupSingleOption";
import { CheckPopupYesNo } from "../../organisms/CheckPopup/CheckPopupYesNo";
import { CheckStepper } from "../../organisms/CheckStepper/CheckStepper";
import { Button } from "../../ui/button";

interface ICheckTemplateProps {
  checkViewModel: ICheckBaseViewModel;
}

export type SignatureCheck = z.infer<
  typeof schemas.Checklab_WebApi_Features_Inspections_Dtos_Checks_InspectionCheckDto
> & { signed: boolean };

export const CheckTemplate: React.FC<ICheckTemplateProps> = observer(
  ({ checkViewModel }) => {
    const { serialNumber, checkUid } = useParams<{
      serialNumber: string;
      checkUid: string;
    }>();
    const navigate = useNavigate();
    const check = checkViewModel;
    const {
      checklist: { signed },
    } = useContext(ChecklistContext);
    const theme = useTheme();
    const { checklist } = useContext(ChecklistContext);
    const { openNextCheck } = useContext(SettingsContext);
    const mediumUp = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`);
    const group = checklist.groups.find((s) =>
      s.checks.map((c) => c.uid).includes(check.uid),
    );
    const [searchParams] = useSearchParams();
    const open = searchParams.get("check-popup");
    const { t } = useTranslation();

    const handleCheckFinished = () => {
      if (!openNextCheck) {
        if (mediumUp) return;

        navigate(`/${OperatorRoutePath.ChecklistBase}/${serialNumber}`, {
          state: { isPreviousPage: true, animate: true },
        });

        return;
      }

      if (checklist.getPercentage() >= 100) {
        navigate(`/${OperatorRoutePath.ChecklistBase}/${serialNumber}`, {
          state: { isPreviousPage: true, animate: true },
        });

        document.body.scrollTo(0, 0);

        return;
      }

      const checks = checklist.groups
        .map((s) => s.checks.filter((c) => !c.finished || c.uid === checkUid))
        .flat();

      if (checks.length < 1) return;

      const currentCheckIndex = checks.findIndex((c) => c.uid === checkUid);

      if (!checks[currentCheckIndex].finished) {
        return;
      }

      const nextCheck =
        checks[
          currentCheckIndex === checks.length - 1 ? 0 : currentCheckIndex + 1
        ];

      setTimeout(() => {
        navigate(
          `/${OperatorRoutePath.ChecklistBase}/${serialNumber}/${OperatorRoutePath.CheckBase}/${nextCheck.uid}/?check-popup=open`,
          {
            state: { isPreviousPage: false, animate: false },
          },
        );
      }, 300);
    };

    const handleStepClick = (checkUid: string) => {
      // Todo: The date is a temp fix for opening group when locating a check from check page.
      navigate(
        `/${OperatorRoutePath.ChecklistBase}/${serialNumber}/${
          OperatorRoutePath.CheckBase
        }/${checkUid}?date=${new Date().toISOString()}&check-popup=open`,
        {
          state: { isPreviousPage: false, animate: false },
        },
      );
    };

    const steps = checklist.groups
      .map((s) => s.checks)
      .flat()
      .map((c) => ({ key: c.uid, done: c.finished, loading: c.loading }));
    const activeIndex = steps.findIndex((s) => s.key === checkUid);
    const activeIndexRelativeToSection = checklist.groups
      .find((s) => s.checks.map((c) => c.uid).includes(checkUid ?? ""))
      ?.checks.findIndex((c) => c.uid === checkUid);
    const activeSection = checklist.groups.find((s) =>
      s.checks.map((c) => c.uid).includes(checkUid ?? ""),
    );

    function handlePrevious() {
      const checkIndex = steps.findIndex((s) => s.key === checkUid);
      const previousCheckUid =
        activeIndex !== 0 ? steps[checkIndex - 1] : steps[checkIndex];

      if (previousCheckUid !== undefined) {
        handleStepClick(previousCheckUid.key);
      }
    }

    function handleNext() {
      const checkIndex = steps.findIndex((s) => s.key === checkUid);
      const nextCheckUid =
        activeIndex !== steps.length - 1
          ? steps[checkIndex + 1]
          : steps[checkIndex];

      if (nextCheckUid !== undefined) {
        handleStepClick(nextCheckUid.key);
      }
    }

    function handleFirst() {
      if (steps.length === 0) return;

      handleStepClick(steps[0].key);
    }

    function handleLast() {
      if (steps.length === 0) return;

      handleStepClick(steps[steps.length - 1].key);
    }

    return (
      <div
        className={`h-full 1pb-14 z-30 top-0 left-0 w-full ${
          open === "open" ? "md:relative fixed" : "hidden"
        }`}
      >
        <div
          className="hide-scrollbar bg-surface-container dark:bg-surface-container rounded-xl h-full py-6 overflow-y-auto overflow-x-hidden md:h-[calc(100vh-16px)]"
          // className="hide-scrollbar 1bg-card/50 bg-card rounded-xl h-full py-6 overflow-y-auto overflow-x-hidden md:h-[calc(100vh-64px)]"
          style={
            {
              // height: "calc(100vh - 64px)",
            }
          }
        >
          <PageLayout
            prevRoute={`/${OperatorRoutePath.ChecklistBase}/${serialNumber}`}
          >
            <div className="flex-grow">
              <div className="px-6 mb-1 flex items-center">
                <div
                  className="block md:hidden"
                  onClick={() =>
                    navigate(
                      `/${OperatorRoutePath.ChecklistBase}/${serialNumber}/check/${checkUid}/?check-popup=false`,
                    )
                  }
                >
                  <ArrowLeft className="w-5 h-5 text-muted-foreground mr-2" />
                </div>
                <span className="text-3xl">Check</span>
              </div>
              <div className="grid grid-cols-2 items-center px-6 mb-6 justify-between">
                <div>
                  <span className="text-muted-foreground text-base font-medium">
                    {activeSection?.title}{" "}
                    {(activeIndexRelativeToSection ?? 0) + 1} /{" "}
                    {activeSection?.checks.length}
                  </span>
                </div>
                <div className="flex col-span-2 xl:col-span-1 xl:mt-0 mt-3 ml-0 gap-x-2 xl:ml-auto w-fit">
                  <Button
                    disabled={activeIndex === 0}
                    onClick={handleFirst}
                    className="h-8 w-8 flex items-center justify-center rounded-full bg-surface-low dark:bg-surface-high hover:opacity-80 dark:hover:bg-surface-high/80 dark:focus:bg-surface-high focus:bg-surface-low cursor-pointer ease-out duration-150"
                  >
                    <div>
                      <ChevronsLeft className="w-5 h-5 text-muted-foreground" />
                    </div>
                  </Button>
                  <Button
                    disabled={activeIndex === 0}
                    onClick={handlePrevious}
                    className="h-8 w-8 flex items-center justify-center rounded-full bg-surface-low dark:bg-surface-high hover:opacity-80 dark:hover:bg-surface-high/80 dark:focus:bg-surface-high focus:bg-surface-low cursor-pointer ease-out duration-150"
                  >
                    <div>
                      <ChevronLeft className="w-5 h-5 text-muted-foreground" />
                    </div>
                  </Button>
                  <Button
                    // disabled={activeIndex === steps.length - 1}
                    onClick={() => {
                      const nextCheck = steps.find((s) => !s.done);

                      if (nextCheck === undefined) {
                        return;
                      }

                      handleStepClick(nextCheck.key);
                    }}
                    className="h-8 w-8 flex items-center justify-center rounded-full bg-surface-low dark:bg-surface-high hover:opacity-80 dark:hover:bg-surface-high/80 dark:focus:bg-surface-high focus:bg-surface-low cursor-pointer ease-out duration-150"
                  >
                    <div>
                      <Locate className="w-5 h-5 text-muted-foreground" />
                    </div>
                  </Button>
                  <Button
                    disabled={activeIndex === steps.length - 1}
                    onClick={handleNext}
                    className="h-8 w-8 flex items-center justify-center rounded-full bg-surface-low dark:bg-surface-high hover:opacity-80 dark:hover:bg-surface-high/80 dark:focus:bg-surface-high focus:bg-surface-low cursor-pointer ease-out duration-150"
                  >
                    <div>
                      <ChevronRight className="w-5 h-5 text-muted-foreground" />
                    </div>
                  </Button>
                  <Button
                    disabled={activeIndex === steps.length - 1}
                    onClick={handleLast}
                    className="h-8 w-8 flex items-center justify-center rounded-full bg-surface-low dark:bg-surface-high hover:opacity-80 dark:hover:bg-surface-high/80 dark:focus:bg-surface-high focus:bg-surface-low cursor-pointer ease-out duration-150"
                  >
                    <div>
                      <ChevronsRight className="w-5 h-5 text-muted-foreground" />
                    </div>
                  </Button>
                </div>
                <div className="hidden col-span-2 mt-4 overflow-x-auto px-1">
                  <div className="flex items-center w-fit">
                    {steps.map((step) => (
                      <div
                        className="py-2 cursor-pointer group"
                        onClick={() => handleStepClick(step.key)}
                        key={step.key}
                      >
                        <div
                          className={`duration-75 ease-out h-1 mr-2 w-8 group-hover:outline-white/25 group-hover:outline-offset-2 group-hover:outline rounded-full ${
                            step.done
                              ? "bg-green-400"
                              : "bg-muted-foreground/30"
                          }
                      ${
                        step.key === checkUid
                          ? "outline-white/75 outline-offset-2 outline"
                          : ""
                      }`}
                        ></div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <CheckStepper
                steps={steps}
                activeIndex={activeIndex}
                onStepClick={handleStepClick}
              />
              {check.type === CheckTypes.okNotOk && (
                <CheckPopupOkNotOk
                  key={check.uid}
                  onFinish={handleCheckFinished}
                  check={check as ICheckOkNotOkViewModel}
                  signed={signed}
                  sectionTitle={group?.title ?? ""}
                />
              )}
              {check.type === CheckTypes.yesNo && (
                <CheckPopupYesNo
                  key={check.uid}
                  onFinish={handleCheckFinished}
                  check={check as ICheckYesNoViewModel}
                  signed={signed}
                  sectionTitle={group?.title ?? ""}
                  groupUid={group?.uid ?? ""}
                  checklistUid={checklist.uid}
                />
              )}
              {check.type === CheckTypes.signature && (
                <CheckPopupSignature
                  key={check.uid}
                  onFinish={handleCheckFinished}
                  check={check as ICheckSignatureViewModel}
                  signed={signed}
                  sectionTitle={group?.title ?? ""}
                  groupUid={group?.uid ?? ""}
                  checklistUid={checklist.uid}
                />
              )}
              {check.type === CheckTypes.scan && (
                <CheckPopupScan
                  key={check.uid}
                  onFinish={handleCheckFinished}
                  check={check as ICheckScanViewModel}
                  signed={signed}
                  sectionTitle={group?.title ?? ""}
                />
              )}
              {check.type === CheckTypes.checklist && (
                <CheckPopupChecklist
                  key={check.uid}
                  onFinish={handleCheckFinished}
                  check={check as ICheckChecklistViewModel}
                  signed={signed}
                  sectionTitle={group?.title ?? ""}
                />
              )}
              {check.type === CheckTypes.inputSingleLine &&
                !check.title.includes(":stempel:") && (
                  <CheckPopupInputSingleLine
                    key={check.uid}
                    onFinish={handleCheckFinished}
                    check={check as ICheckInputSingleLineViewModel}
                    title={
                      check.title.includes(":date:")
                        ? t("inspectionPage.checkPopup.dateCheck.button")
                        : t("inspectionPage.checkPopup.singleLine.enterText")
                    }
                    signed={signed}
                    sectionTitle={group?.title ?? ""}
                  />
                )}
              {check.type === CheckTypes.inputSingleLine &&
                check.title.includes(":stempel:") && (
                  <CheckPopupInputSingleLinePrint
                    key={check.uid}
                    onFinish={handleCheckFinished}
                    check={check as ICheckInputSingleLineViewModel}
                    title={"Print label"}
                    signed={signed}
                    sectionTitle={group?.title ?? ""}
                  />
                )}

              {check.type === CheckTypes.inputNumber && (
                <CheckPopupInputNumber
                  key={check.uid}
                  onFinish={handleCheckFinished}
                  check={check as ICheckInputNumberViewModel}
                  title={t(
                    "inspectionPage.checkPopup.numberCheck.numberButton",
                  )}
                  signed={signed}
                  sectionTitle={group?.title ?? ""}
                />
              )}
              {check.type === CheckTypes.singleOption && (
                <CheckPopupSingleOption
                  key={check.uid}
                  onFinish={handleCheckFinished}
                  check={check as ICheckSingleOptionViewModel}
                  signed={signed}
                  sectionTitle={group?.title ?? ""}
                  groupUid={group?.uid ?? ""}
                  checklistUid={checklist.uid}
                />
              )}
              {check.type === CheckTypes.photo && (
                <CheckPopupPhoto
                  key={check.uid}
                  onFinish={handleCheckFinished}
                  check={check as ICheckPhotoViewModel}
                  signed={signed}
                  sectionTitle={group?.title ?? ""}
                  groupUid={group?.uid ?? ""}
                  checklistUid={checklist.uid}
                />
              )}
            </div>
          </PageLayout>
        </div>
      </div>
    );
  },
);
