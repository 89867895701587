export enum ResultFailureReason {
  DomainError = "domain-error",
  ResourceNotFound = "resource-not-found",
  PermissionDenied = "permission-denied",
  ServerError = "server-error",
  FetchError = "fetch-error",
  Unknown = "unknown",
}

export interface IResultFailure {
  readonly message: string;
  readonly code: string;
  readonly failureReason: ResultFailureReason;
}

export interface IResult {
  readonly isSuccessful: boolean;
  readonly isNotSuccessful: boolean;
  readonly failure: IResultFailure | undefined;
}

export interface IContentResult<T> extends IResult {
  content: T;
}

export type ISuccessfulResult = IResult;

export interface ISuccessfulContentResult<T>
  extends ISuccessfulResult,
    IContentResult<T> {}

export type IUnsuccessfulResult = IResult;

export interface IUnsuccessfulContentResult<T>
  extends IUnsuccessfulResult,
    IContentResult<T> {}
