import { Entity } from "../Entity";
import { ChecklistState } from "../../../../infrastructure/api/common/types";

interface IChecklistSearchItemEntity {
  serialNumber: string;
  percentage: number;
  title: string;
  state: ChecklistState;
}

export class ChecklistSearchItemEntity
  extends Entity
  implements IChecklistSearchItemEntity
{
  public readonly serialNumber: string;
  public readonly percentage: number;
  public readonly title: string;
  public readonly state: ChecklistState;

  protected constructor(checklistSearchItem: IChecklistSearchItemEntity) {
    super(checklistSearchItem.serialNumber);

    this.serialNumber = checklistSearchItem.serialNumber;
    this.percentage = checklistSearchItem.percentage;
    this.title = checklistSearchItem.title;
    this.state = checklistSearchItem.state;
  }

  public static new(
    checklistSearchItem: IChecklistSearchItemEntity,
  ): ChecklistSearchItemEntity {
    return new ChecklistSearchItemEntity(checklistSearchItem);
  }

  public static existing(
    checklistSearchItem: IChecklistSearchItemEntity,
  ): ChecklistSearchItemEntity {
    return new ChecklistSearchItemEntity(checklistSearchItem);
  }
}
