import { useAuth } from "@clerk/clerk-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { PropsWithChildren, useEffect } from "react";
import { authenticate } from "../../api/";

export const queryClient = new QueryClient();

export default function QueryClientContainer({ children }: PropsWithChildren) {
  // {
  //   queryCache: new QueryCache({
  //     onError: () => {
  //       alert("ERROR");
  //     },
  //   }),
  // }

  const { getToken } = useAuth();

  useEffect(() => {
    authenticate(async () => await getToken({ template: "jwt-template-1" }));
  }, [getToken]);

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
}
