import { makeObservable, observable, runInAction } from "mobx";
import deleteCommentCommand from "../../api/delete-comment";
import api from "../../api/index";
import { CheckSetValueCommand } from "../../core/commands/check/CheckSetValueCommand";
import { CreateCommentCommand } from "../../core/commands/check/CreateCommentCommand";
import { CreateCommentPhotosCommand } from "../../core/commands/check/CreateCommentPhotosCommand";
import { PhotoEntity } from "../../core/domain/entities/checklist/PhotoEntity";
import { CheckBaseEntity } from "../../core/domain/entities/checklist/check/CheckBaseEntity";
import { WorkInstructionQuery } from "../../core/queries/WorkInstructionQuery";
import { IContentResult } from "../../core/results/Result";
import { CheckState } from "../../infrastructure/api/common/types";
import { ISetCheckValueResponse } from "../../infrastructure/api/endpoints/common/response.types";
import { ActivityViewModel } from "./ActivityViewModel";
import { CheckTypes } from "./CheckOkNotOkViewModel";
import { InstructionViewModel } from "./InstructionViewModel";
import { PhotoViewModel } from "./PhotoViewModel";

export type OriginReference = {
  type: 0 | 1 | 2 | 3;
  value: string;
};

export interface ICheckBaseViewModel {
  uid: string;
  title: string;
  type: CheckTypes;
  state: CheckState;
  locations: {
    id: number;
    name: string;
    description: string;
    workspaceId: 0;
  }[];
  activities: ActivityViewModel[];
  finished: boolean;
  isIoT: boolean;
  required: boolean;
  loading: boolean;
  instructionId: string | undefined;
  instruction: InstructionViewModel | undefined;
  lastTouchedBy: OriginReference | null;
  lastTouchedAt: Date | null;

  addActivity(message: string, photos?: File[]): Promise<void>;
  isFinished: () => boolean;
  setWorkInstruction(): Promise<void>;
  deleteComment(commentUid: string): Promise<void>;
  editComment(commentUid: string, value: string): Promise<void>;
  updateCheck(check: CheckBaseEntity): void;
  updateSignalRCheck(check: any): void;

  [x: string]: any;
}

export abstract class CheckBaseViewModel implements ICheckBaseViewModel {
  public uid: string = "";
  public title: string = "";
  public type: CheckTypes = 1;
  public state: CheckState = 1;
  public locations: {
    id: number;
    name: string;
    description: string;
    workspaceId: 0;
  }[] = [];
  public activities: ActivityViewModel[] = [];
  public finished: boolean = false;
  public required: boolean = false;
  public isIoT: boolean = false;
  public loading: boolean = false;
  public instructionId: string | undefined = undefined;
  public instruction: InstructionViewModel | undefined = undefined;
  public lastTouchedBy: OriginReference | null = null;
  public lastTouchedAt: Date | null = null;

  private readonly checklistUid: string;
  private readonly groupUid: string;

  private _checkSetValueCommand = new CheckSetValueCommand();
  private _createCommentCommand = new CreateCommentCommand();
  private _createCommentPhotosCommand = new CreateCommentPhotosCommand();
  private _workInstructionQuery = new WorkInstructionQuery();

  public updateSignalRCheck(check: CheckBaseEntity) {
    runInAction(async () => {
      this.uid = check.uid;
      this.title = check.description;
      this.type = check.type;
      this.state = check.state;
      this.locations = check.locations;
      // TODO: backend activities array missing on SignalR response.
      this.activities =
        check.activities
          ?.map((a: any) => new ActivityViewModel(a))
          .sort(
            (a: any, b: any) =>
              new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
          )
          .reverse() ?? [];

      this.instructionId = check.workInstructionId;
      this.required = check.required;
      this.isIoT = check.isIoT;
      this.loading = false;
      this.lastTouchedBy = check.lastTouchedBy;
      this.lastTouchedAt = check.lastTouchedAt;

      this.finished = this.isFinished();
    });
  }

  public updateCheck(check: CheckBaseEntity) {
    runInAction(async () => {
      this.uid = check.uid;
      this.title = check.description;
      this.type = check.type;
      this.state = check.state;
      this.locations = check.locations;
      // TODO: backend activities array missing on SignalR response.
      this.activities =
        check.activities
          ?.map((a) => new ActivityViewModel(a))
          .sort(
            (a, b) =>
              new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
          )
          .reverse() ?? [];

      this.instructionId = check.workInstructionId;
      this.required = check.required;
      this.isIoT = check.isIoT;
      this.loading = false;
      this.lastTouchedBy = check.lastTouchedBy;
      this.lastTouchedAt = check.lastTouchedAt;

      this.finished = this.isFinished();
    });
  }

  protected constructor(
    check: CheckBaseEntity,
    checklistUid: string,
    groupUid: string,
  ) {
    this.updateCheck(check);

    this.checklistUid = checklistUid;
    this.groupUid = groupUid;

    makeObservable(this, {
      uid: observable,
      title: observable,
      type: observable,
      state: observable,
      finished: observable,
      instruction: observable,
      loading: observable,
      activities: observable,
    });
  }

  protected async update<T>(
    value: T,
    onSuccess: () => void,
    onError: () => void,
  ): Promise<void> {
    runInAction(() => {
      onSuccess();
    });

    try {
      const saveResult = await this.handleSetValue<T>(value);

      if (saveResult.isNotSuccessful) {
        runInAction(() => {
          onError();
        });

        return;
      }

      this.state = saveResult.content.state;

      const x = { state: { selectedUser: { id: "" } } };

      const userId = //@ts-ignore
        window.Clerk.user.id === process.env.REACT_APP_ADMIN_USER_ID
          ? JSON.parse(
              localStorage.getItem("user-storage") || JSON.stringify(x),
            ).state.loggedInUser?.userId
          : //@ts-ignore
            window.Clerk.user.id;

      this.lastTouchedBy = {
        value: userId,
        type: 1,
      };
      this.lastTouchedAt = new Date();
      this.finished = this.isFinished();
    } catch (e: unknown) {
      const { message } = e as Error;
      runInAction(() => {
        onError();
        this.loading = false;
        this.finished = this.isFinished();

        alert(message);
      });
    }
  }

  protected async handleSetValue<T>(
    value: T,
  ): Promise<IContentResult<ISetCheckValueResponse>> {
    runInAction(() => {
      this.loading = true;
    });

    const request = {
      checklistUid: this.checklistUid,
      groupUid: this.groupUid,
      checkUid: this.uid,
      checkType: this.type,
      value,
    };

    const res = await this._checkSetValueCommand.handle(request);

    runInAction(() => {
      this.loading = false;
    });

    return res;
  }

  public async addPhotosToComment(
    commentUid: string,
    photos: File[],
  ): Promise<void> {
    const createCommentPhotosResult =
      await this._createCommentPhotosCommand.handle(
        this.checklistUid,
        this.groupUid,
        this.uid,
        commentUid,
        photos,
      );

    if (createCommentPhotosResult.isNotSuccessful) {
      throw Error(
        `${createCommentPhotosResult.failure?.failureReason}: ${createCommentPhotosResult.failure?.message}`,
      );
    }
  }

  public async addActivity(message: string, photos: File[]): Promise<void> {
    const createActivityResult = await this._createCommentCommand.handle(
      this.checklistUid,
      this.groupUid,
      this.uid,
      message,
    );

    if (createActivityResult.isNotSuccessful) {
      throw Error(
        `${createActivityResult.failure?.failureReason}: ${createActivityResult.failure?.message}`,
      );
    }

    const activityViewModel = new ActivityViewModel(
      createActivityResult.content,
    );

    if (photos.length > 0) {
      await this.addPhotosToComment(createActivityResult.content.uid, photos);
    }

    runInAction(() => {
      this.activities = [
        {
          ...activityViewModel,
          photos:
            photos?.map(
              (p) =>
                new PhotoViewModel(PhotoEntity.new(URL.createObjectURL(p))),
            ) ?? [],
        },
        ...this.activities,
      ];
    });
  }

  public async setWorkInstruction() {
    if (!this.instructionId) {
      throw Error("This check has no instruction.");
    }

    const { content, isNotSuccessful, failure } =
      await this._workInstructionQuery.handle({
        uid: this.instructionId,
      });

    if (isNotSuccessful) {
      throw Error(`${failure?.failureReason}: ${failure?.message}`);
    }

    runInAction(() => {
      this.instruction = new InstructionViewModel(content);
    });
  }

  public isFinished(): boolean {
    return (
      this.state === CheckState.Good ||
      this.state === CheckState.Tolerated ||
      this.state === CheckState.Neutral ||
      this.state === CheckState.Recovered
    );
  }

  public async deleteComment(commentUid: string): Promise<void> {
    runInAction(() => {
      this.activities = this.activities.filter((a) => a.uid !== commentUid);
    });

    const request = {
      checklistUid: this.checklistUid,
      groupUid: this.groupUid,
      checkUid: this.uid,
      commentUid,
    };

    await deleteCommentCommand(request);
  }

  public async editComment(commentUid: string, value: string): Promise<void> {
    const activity = this.activities.find((a) => a.uid === commentUid);

    if (!activity || value === "") {
      return;
    }

    const tempActivity = { ...activity, message: value };
    const activityIndex = this.activities.findIndex(
      (a) => a.uid === commentUid,
    );
    const tempActivities = [...this.activities];

    tempActivities[activityIndex] = tempActivity;

    runInAction(() => {
      this.activities = tempActivities;
    });

    api.checks.put("/checklists/groups/checks/comments", {
      checklistId: this.checklistUid,
      groupId: this.groupUid,
      checkId: this.uid,
      commentId: commentUid,
      message: value,
    });
  }
}
