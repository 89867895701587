import { ResultFailureReason } from "../../../core/results/Result";

export class FailureReasonMapper {
  public static fromErrorReason(reason: string): ResultFailureReason {
    switch (reason) {
      case 'DomainError': return ResultFailureReason.DomainError;
      case 'ResourceNotFound': return ResultFailureReason.ResourceNotFound;
      case 'PermissionDenied': return ResultFailureReason.PermissionDenied;
      default: return ResultFailureReason.Unknown;
    }
  }
}
