import axios from "axios";
import qs from "qs";

const x = { state: { selectedUser: { id: "" } } };

export const instance = axios.create({
  timeout: 20000,
  headers: {
    "CL-USER-ID": "",
  },
  transformRequest: [
    function (data, headers) {
      headers["CL-USER-ID"] =
        //@ts-ignore
        window.Clerk.user.id === process.env.REACT_APP_ADMIN_USER_ID
          ? JSON.parse(
              localStorage.getItem("user-storage") || JSON.stringify(x),
            ).state.loggedInUser?.userId
          : //@ts-ignore
            window.Clerk.user.id;

      if (data instanceof FormData) {
        headers["Content-Type"] = "multipart/form-data";

        return data;
      }

      headers["Content-Type"] = "application/json";

      return JSON.stringify(data);
    },
  ],
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
  transformResponse: [
    function (data) {
      let res = undefined;

      try {
        res = JSON.parse(data).content;

        if (res === undefined) {
          res = JSON.parse(data);
        }
      } catch (e) {
        res = JSON.parse(data);
      }

      return res;
    },
  ],
});
