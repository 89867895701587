import {
  IContentResult,
  ResultFailureReason,
} from "../../../core/results/Result";
import { SuccessfulContentResult } from "../../../core/results/successful/SuccessfulResult";
import { UnsuccessfulContentResult } from "../../../core/results/unsuccessful/UnsuccessfulResult";
import { EndpointMethod } from "./EndpointMethod";
import { ResponseBase } from "./types";

const x = { state: { selectedUser: { id: "" } } };

export class PutMethod<T> extends EndpointMethod {
  public async execute(body?: any): Promise<IContentResult<T>> {
    try {
      const clUserId = //@ts-ignore
        window.Clerk.user.id === process.env.REACT_APP_ADMIN_USER_ID
          ? JSON.parse(
              localStorage.getItem("user-storage") || JSON.stringify(x),
            ).state.loggedInUser?.userId
          : //@ts-ignore
            window.Clerk.user.id;

      const result = await fetch(`${this.path}`, {
        method: "PUT",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
          "CL-USER-ID": clUserId,
          ...this.headers,
        },
      });

      if (!result.ok) {
        const errorMessage = await result.text();

        return new UnsuccessfulContentResult({
          failureReason: ResultFailureReason.FetchError,
          code: "result-not-ok",
          message: errorMessage,
        });
      }

      const response: ResponseBase<T> = await result.json();
      if (response.success)
        return new SuccessfulContentResult(response.content);

      return this.mapToUnsuccessfulResult(response);
    } catch (e) {
      return new UnsuccessfulContentResult({
        failureReason: ResultFailureReason.ServerError,
        code: "failed-to-fetch",
        message: "Failed to fetch",
      });
    }
  }
}
