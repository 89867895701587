import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { injectable } from "inversify";
import { IResult, ResultFailureReason } from "../../../core/results/Result";
import { SuccessfulResult } from "../../../core/results/successful/SuccessfulResult";
import { UnsuccessfulResult } from "../../../core/results/unsuccessful/UnsuccessfulResult";
import { getToken } from "../../../infrastructure/services/authentication/AuthenticationService";

@injectable()
export class SignalRService {
  public _connection: HubConnection | undefined;

  public async start(hub: string): Promise<IResult> {
    try {
      const tokenResult = await getToken();

      if (!tokenResult.isSuccessful) {
        return new UnsuccessfulResult({
          code: "signalR-start-error",
          failureReason: ResultFailureReason.Unknown,
          message: "No access token...",
        });
      }

      this._connection = new HubConnectionBuilder()
        .withUrl(hub, {
          accessTokenFactory: () => tokenResult.content,
        })
        .withAutomaticReconnect()
        .build();

      await this._connection.start();

      return new SuccessfulResult();
    } catch (e) {
      return new UnsuccessfulResult({
        code: "signalR-start-error",
        failureReason: ResultFailureReason.Unknown,
        message: (e as Error).message,
      });
    }
  }

  public async invoke(
    method: string,
    ...args: (string | number)[]
  ): Promise<IResult> {
    try {
      await this._connection?.invoke(method, ...args);

      return new SuccessfulResult();
    } catch (e) {
      return new UnsuccessfulResult({
        code: "signalR-invoke-error",
        failureReason: ResultFailureReason.Unknown,
        message: (e as Error).message,
      });
    }
  }

  public onCallback<T>(method: string, callback: (data: T) => void): void {
    this._connection?.on(method, (data: T) => callback(data));
  }
}
