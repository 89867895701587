import { useUser } from "@clerk/clerk-react";
import React, {
  createContext,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
import { useUserStore } from "../../../stores/project-store";

interface ISettingsManagerState {
  toggleDarkMode: () => void;
  toggleGroupChecks: () => void;
  toggleOpenNextCheck: () => void;
  toggleDefaultOpenSections: () => void;
  toggleIsMemor10: () => void;
  toggleIsZebraScanner: () => void;
  updateActiveLocationIds: (activeLocationIds: number[]) => void;
  updateBarcodeRegex: (value: string) => void;
  updateBarcodePrefix: (value: string) => void;
  updateBarcodeSuffix: (value: string) => void;
  updateLanguageCode: (value: string) => void;
  darkMode: boolean;
  isMemor10: boolean;
  isZebraScanner: boolean;
  groupChecks: boolean;
  openNextCheck: boolean;
  defaultOpenSections: boolean;
  barcodeRegex: string;
  barcodePrefix: string;
  barcodeSuffix: string;
  languageCode: string;
  activeLocationIds: number[];
  logout: () => void;
}

export const SettingsContext = createContext<ISettingsManagerState>({
  toggleDarkMode: () => {
    throw new Error("SettingsContext not initialized yet.");
  },
  toggleGroupChecks: () => {
    throw new Error("SettingsContext not initialized yet.");
  },
  toggleOpenNextCheck: () => {
    throw new Error("SettingsContext not initialized yet.");
  },
  toggleDefaultOpenSections: () => {
    throw new Error("SettingsContext not initialized yet.");
  },
  toggleIsMemor10: () => {
    throw new Error("SettingsContext not initialized yet.");
  },
  toggleIsZebraScanner: () => {
    throw new Error("SettingsContext not initialized yet.");
  },
  updateActiveLocationIds: () => {
    throw new Error("SettingsContext not initialized yet.");
  },
  updateBarcodeRegex: () => {
    throw new Error("SettingsContext not initialized yet.");
  },
  updateBarcodePrefix: () => {
    throw new Error("SettingsContext not initialized yet.");
  },
  updateBarcodeSuffix: () => {
    throw new Error("SettingsContext not initialized yet.");
  },
  updateLanguageCode: () => {
    throw new Error("SettingsContext not initialized yet.");
  },
  logout: () => {
    throw new Error("SettingsContext not initialized yet.");
  },
  darkMode: true,
  groupChecks: false,
  openNextCheck: true,
  isMemor10: false,
  isZebraScanner: false,
  defaultOpenSections: false,
  barcodeRegex: "",
  barcodePrefix: "",
  barcodeSuffix: "",
  languageCode: "",
  activeLocationIds: [],
});

const DARKMODE_KEY = "dark-mode";
const GROUP_CHECKS_KEY = "group-checks-mode";
const OPEN_NEXT_CHECK = "open-next-check";
const DEFAULT_OPEN_SECTIONS = "default-open-groups";
const IS_MEMOR_10 = "is-memor-10";
const IS_ZEBRA_SCANNER = "is-zebra-scanner";
const ACTIVE_LOCATION_IDS = "active-locations-ids";
const BARCODE_REGEX = "barcode-regex";
const BARCODE_PREFIX = "barcode-prefix";
const BARCODE_SUFFIX = "barcode-suffix";
const LANGUAGE_CODE = "language-code";

export const SettingsManager: React.FC<PropsWithChildren> = ({ children }) => {
  const getValue = <T,>(key: string): T | null => {
    const val = localStorage.getItem(key);

    if (val) {
      return JSON.parse(val) as T;
    }

    return null;
  };

  const [darkMode, setDarkMode] = useState(
    getValue<boolean>(DARKMODE_KEY) ?? true,
  );

  const [isMemor10, setIsMemor10] = useState(
    getValue<boolean>(IS_MEMOR_10) ?? false,
  );

  const [isZebraScanner, setIsZebraScanner] = useState(
    getValue<boolean>(IS_ZEBRA_SCANNER) ?? false,
  );

  const [groupChecks, setGroupChecks] = useState(
    getValue<boolean>(GROUP_CHECKS_KEY) ?? false,
  );

  const [openNextCheck, setOpenNextCheck] = useState(
    getValue<boolean>(OPEN_NEXT_CHECK) ?? true,
  );
  const [defaultOpenSections, setDefaultOpenSections] = useState(
    getValue<boolean>(DEFAULT_OPEN_SECTIONS) ?? false,
  );
  const [activeLocationIds, setActiveLocationIds] = useState(
    getValue<number[]>(ACTIVE_LOCATION_IDS) ?? [],
  );
  const [barcodeRegex, setBarcodeRegex] = useState(
    getValue<string>(BARCODE_REGEX) ?? "[^B0]([0-9])*[^E]",
  );
  const [barcodePrefix, setBarcodePrefix] = useState(
    getValue<string>(BARCODE_PREFIX) ?? "scan-start:",
  );
  const [barcodeSuffix, setBarcodeSuffix] = useState(
    getValue<string>(BARCODE_SUFFIX) ?? ":scan-end",
  );
  const [languageCode, setLanguageCode] = useState(
    //TODO-translations: use correct format nl-NL
    getValue<string>(LANGUAGE_CODE) ?? "nl-NL",
  );

  const toggleDarkMode = () => {
    localStorage.setItem(DARKMODE_KEY, !darkMode ? "true" : "false");
    setDarkMode(!darkMode);

    if (!darkMode) {
      document.querySelector("html")?.classList.add("dark");
    } else {
      document.querySelector("html")?.classList.remove("dark");
    }
  };

  const toggleGroupChecks = () => {
    localStorage.setItem(GROUP_CHECKS_KEY, !groupChecks ? "true" : "false");
    setGroupChecks(!groupChecks);
  };

  const toggleOpenNextCheck = () => {
    localStorage.setItem(OPEN_NEXT_CHECK, !openNextCheck ? "true" : "false");
    setOpenNextCheck(!openNextCheck);
  };

  const toggleIsMemor10 = () => {
    localStorage.setItem(IS_MEMOR_10, !isMemor10 ? "true" : "false");
    setIsMemor10(!isMemor10);
  };

  const toggleIsZebraScanner = () => {
    localStorage.setItem(IS_ZEBRA_SCANNER, !isZebraScanner ? "true" : "false");
    setIsZebraScanner(!isZebraScanner);
  };

  const updateBarcodeRegex = (value: string) => {
    localStorage.setItem(BARCODE_REGEX, JSON.stringify(value));
    setBarcodeRegex(value);
  };

  const updateBarcodePrefix = (value: string) => {
    localStorage.setItem(BARCODE_PREFIX, JSON.stringify(value));
    setBarcodePrefix(value);
  };

  const updateBarcodeSuffix = (value: string) => {
    localStorage.setItem(BARCODE_SUFFIX, JSON.stringify(value));
    setBarcodeSuffix(value);
  };

  const updateLanguageCode = (value: string) => {
    localStorage.setItem(LANGUAGE_CODE, JSON.stringify(value));
    setLanguageCode(value);
  };

  const updateActiveLocationIds = (activeLocationIds: number[]) => {
    localStorage.setItem(
      ACTIVE_LOCATION_IDS,
      JSON.stringify(activeLocationIds),
    );
    setActiveLocationIds(activeLocationIds);
  };

  const toggleDefaultOpenSections = () => {
    localStorage.setItem(
      DEFAULT_OPEN_SECTIONS,
      !defaultOpenSections ? "true" : "false",
    );
    setDefaultOpenSections(!defaultOpenSections);
  };

  const logout = async () => {
    //await logoutCommand();
  };

  useEffect(() => {
    if (darkMode) {
      document.querySelector("html")?.classList.add("dark");
    } else {
      document.querySelector("html")?.classList.remove("dark");
    }
  }, []);

  const { user } = useUser();

  useEffect(() => {
    if (user && user.unsafeMetadata) {
      const userLanguageCode = user.unsafeMetadata.language as
        | string
        | undefined;

      if (userLanguageCode && userLanguageCode !== languageCode) {
        updateLanguageCode(userLanguageCode);
      }

      if (!userLanguageCode) {
        updateLanguageCode("nl-NL");
      }
    }
  }, [user]);

  const { updateLoggedInUser } = useUserStore();
  const { user: clerkUser } = useUser();

  useEffect(() => {
    if (clerkUser && process.env.REACT_APP_ADMIN_USER_ID !== clerkUser?.id) {
      updateLoggedInUser({
        userId: clerkUser?.id ?? "",
      });
    }
  }, [clerkUser]);

  return (
    <SettingsContext.Provider
      value={{
        updateLanguageCode,
        languageCode,
        isZebraScanner,
        toggleIsZebraScanner,
        toggleDarkMode,
        toggleGroupChecks,
        toggleOpenNextCheck,
        toggleDefaultOpenSections,
        groupChecks,
        darkMode,
        openNextCheck,
        defaultOpenSections,
        isMemor10,
        toggleIsMemor10,
        logout,
        updateActiveLocationIds,
        activeLocationIds,
        updateBarcodeRegex,
        barcodeRegex,
        barcodePrefix,
        barcodeSuffix,
        updateBarcodePrefix,
        updateBarcodeSuffix,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};
