import { PostFormMethod } from "../../../methods/PostFormMethod";
import { ICreatePhotoResponse } from "../../common/response.types";

export class CreateCommentPhotoEndpoint extends PostFormMethod<ICreatePhotoResponse> {
  constructor() {
    super(
      `${process.env.REACT_APP_API_URL}/checklists/groups/checks/comments/photos`,
    );
  }
}
