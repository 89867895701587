import {IResultFailure, ISuccessfulContentResult, ISuccessfulResult} from "../Result";

export class SuccessfulResult implements ISuccessfulResult {
  readonly failure: IResultFailure | undefined = undefined;
  readonly isNotSuccessful: boolean = false;
  readonly isSuccessful: boolean = true;
}

export class SuccessfulContentResult<T> extends SuccessfulResult implements ISuccessfulContentResult<T> {
  readonly content: T;

  constructor(content: T) {
    super();
    this.content = content;
  }
}
