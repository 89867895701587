import { IContentResult } from "../../../core/results/Result";
import { SuccessfulContentResult } from "../../../core/results/successful/SuccessfulResult";
import { IParams } from "../common/types";
import { EndpointMethod } from "./EndpointMethod";
import { ResponseBase } from "./types";

export class GetMethod<T> extends EndpointMethod {
  public async execute(
    uid?: string,
    params?: IParams,
  ): Promise<IContentResult<T>> {
    let url = `${this.path}/${uid ?? ""}`;

    if (params) {
      const paramsString = Object.keys(params)
        .map((param, idx) => {
          return `${idx === 0 ? "?" : "&"}${param}=${
            Object.values(params)[idx]
          }`;
        })
        .join("");

      url = `${url}${paramsString}`;
    }

    const result = await fetch(url, {
      method: "GET",
      headers: { ...this.headers },
    });

    if (!result.ok) {
      const errorMessage = await result.text();

      throw Error(errorMessage);
    }

    const response: ResponseBase<T> = await result.json();
    if (response.success) return new SuccessfulContentResult(response.content);

    return this.mapToUnsuccessfulResult(response);
  }
}
