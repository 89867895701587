import { IChecklistViewModel } from "../../application/viewModels/ChecklistViewModel";
import { createObservableStateHook } from "./createObservableStateHook";

export const useChecklist = (checklistViewModel: IChecklistViewModel) => {
  const uidHook = createObservableStateHook(() => checklistViewModel.uid);
  const idHook = createObservableStateHook(() => checklistViewModel.id);
  const serialNumberHook = createObservableStateHook(
    () => checklistViewModel.serialNumber,
  );
  const titleHook = createObservableStateHook(() => checklistViewModel.title);
  const specialFunctionsHook = createObservableStateHook(
    () => checklistViewModel.specialFunctions,
  );
  const stateHook = createObservableStateHook(() => checklistViewModel.state);
  const signedHook = createObservableStateHook(() => checklistViewModel.signed);
  const sectionsHook = createObservableStateHook(
    () => checklistViewModel.groups,
  );
  const percentageHook = createObservableStateHook(
    () => checklistViewModel.percentage,
  );

  const unfilteredSectionsHook = createObservableStateHook(
    () => checklistViewModel.unfilteredSections,
  );
  const locationsHook = createObservableStateHook(
    () => checklistViewModel.locations,
  );
  const createdByHook = createObservableStateHook(
    () => checklistViewModel.createdBy,
  );
  const acceptedByHook = createObservableStateHook(
    () => checklistViewModel.acceptedBy,
  );

  const uid = uidHook();
  const id = idHook();
  const serialNumber = serialNumberHook();
  const title = titleHook();
  const specialFunctions = specialFunctionsHook();
  const signed = signedHook();
  const state = stateHook();
  const groups = sectionsHook();
  const percentage = percentageHook();
  const unfilteredSections = unfilteredSectionsHook();
  const locations = locationsHook();
  const createdBy = createdByHook();
  const acceptedBy = acceptedByHook();

  return {
    uid,
    id,
    serialNumber,
    title,
    specialFunctions,
    signed,
    state,
    groups,
    percentage,
    unfilteredSections,
    locations,
    createdBy,
    acceptedBy,
    getPercentage: (filtered?: boolean) =>
      checklistViewModel.getPercentage(filtered),
    sign: () => checklistViewModel.sign(),
    signRedo: () => checklistViewModel.signRedo(),
    leaveChecklist: () => checklistViewModel.leaveChecklist(),
  };
};
