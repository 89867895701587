import { EndpointMethod } from "./EndpointMethod";
import { ResponseBase } from "./types";
import { IContentResult } from "../../../core/results/Result";
import { SuccessfulContentResult } from "../../../core/results/successful/SuccessfulResult";

export class PostMethod<T> extends EndpointMethod {
  public async execute(body?: any): Promise<IContentResult<T>> {
    const result = await fetch(`${this.path}`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        ...this.headers
      },
    });

    if (!result.ok) {
      const errorMessage = await result.text();

      throw Error(errorMessage);
    }

    if (result.status === 201)
      return new SuccessfulContentResult(await result.json());

    const response: ResponseBase<T> = await result.json();
    if (response.success)
      return new SuccessfulContentResult(response.content);

    return this.mapToUnsuccessfulResult(response);
  }
}
