import { OriginReference } from "../../../../../application/viewModels/CheckBaseViewModel";
import {
  CheckPriority,
  CheckState,
} from "../../../../../infrastructure/api/common/types";
import { Entity } from "../../Entity";
import { ActivityEntity } from "../ActivityEntity";
import { WorkInstructionEntity } from "../WorkInstructionEntity";

export enum CheckTypes {
  okNotOk = 1,
  inputNumber,
  yesNo,
  inputSingleLine,
  scan,
  checklist,
  singleOption,
  signature = 8,
  photo = 9,
}

export interface ICheckBaseEntity {
  uid: string;
  description: string;
  priority: CheckPriority;
  activities: ActivityEntity[];
  type: CheckTypes;
  state: CheckState;
  locations: {
    id: number;
    name: string;
    description: string;
    workspaceId: 0;
  }[];
  workInstructionId?: string;
  required: boolean;
  isIoT: boolean;
  lastTouchedBy: OriginReference | null;
  lastTouchedAt: Date | null;
}

export class CheckBaseEntity extends Entity implements ICheckBaseEntity {
  public readonly description: string;
  public readonly priority: CheckPriority;
  public readonly activities: ActivityEntity[];
  public readonly workInstruction: WorkInstructionEntity | undefined;
  public readonly workInstructionId: string | undefined;
  public readonly type: CheckTypes;
  public readonly state: CheckState;
  public readonly locations: {
    id: number;
    name: string;
    description: string;
    workspaceId: 0;
  }[];
  public readonly required: boolean;
  public readonly isIoT: boolean;
  public readonly lastTouchedBy: OriginReference | null = null;
  public readonly lastTouchedAt: Date | null = null;

  protected constructor(check: ICheckBaseEntity) {
    super(check.uid);

    this.description = check.description;
    this.priority = check.priority;
    this.activities = check.activities;
    this.workInstructionId = check.workInstructionId;
    this.type = check.type;
    this.state = check.state;
    this.locations = check.locations;
    this.required = check.required;
    this.isIoT = check.isIoT;
    this.lastTouchedBy = check.lastTouchedBy;
    this.lastTouchedAt = check.lastTouchedAt;
  }
}
