import {IResultFailure, IUnsuccessfulContentResult, IUnsuccessfulResult} from "../Result";

export class UnsuccessfulResult implements IUnsuccessfulResult {
  readonly failure: IResultFailure | undefined;
  readonly isNotSuccessful: boolean = true;
  readonly isSuccessful: boolean = false;

  constructor(failure?: IResultFailure) {
    this.failure = failure;
  }
}

export class UnsuccessfulContentResult<T> extends UnsuccessfulResult implements IUnsuccessfulContentResult<T> {
  // @ts-ignore: TS2564
  readonly content: T;
}
