import { injectable } from "inversify";
import { IContentResult } from "../../../core/results/Result";
import { IChecklistDTO } from "../../api/common/types";
import { CheckSetValueEndpoint } from "../../api/endpoints/check/CheckSetValueEndpoint";
import { CreateCommentEndpoint } from "../../api/endpoints/check/comment/CreateCommentEndpoint";
import { CreateCommentPhotoEndpoint } from "../../api/endpoints/check/comment/CreateCommentPhotoEndpoint";
import { CreateChecklistEndpoint } from "../../api/endpoints/checklist/CreateChecklistEndpoint";
import { GetChecklistByIdEndpoint } from "../../api/endpoints/checklist/GetChecklistByIdEndpoint";
import { GetChecklistBySerialEndpoint } from "../../api/endpoints/checklist/GetChecklistBySerialEndpoint";
import { GetChecklistsByPartialSerialNumber } from "../../api/endpoints/checklist/GetChecklistsByPartialSerialNumber";
import { GetChecklistsByTimestampEndpoint } from "../../api/endpoints/checklist/GetChecklistsByTimestampEndpoint";
import { SignChecklistEndpoint } from "../../api/endpoints/checklist/SignChecklistEndpoint";
import { SignUndoChecklistEndpoint } from "../../api/endpoints/checklist/SignUndoChecklistEndpoint";
import {
  ICreateChecklistRequest,
  ICreateCommentPhotoRequest,
  ICreateCommentRequest,
  IGetChecklistsByPartialSerialNumberRequest,
  IGetWorkInstructionByIdRequest,
  ISetCheckValueBaseRequest,
  ISignChecklistRequest,
} from "../../api/endpoints/common/request.types";
import {
  IChecklistByTimestampResponse,
  IChecklistsByPartialSerialNumberResponse,
  ICreateChecklistResponse,
  ICreateCommentResponse,
  ICreatePhotoResponse,
  ISetCheckValueResponse,
} from "../../api/endpoints/common/response.types";
import { GetWorkInstructionByIdEndpoint } from "../../api/endpoints/workinstructions/GetWorkInstructionByIdEndpoint";
import { getToken } from "../authentication/AuthenticationService";

@injectable()
export class ChecklistService {
  private _getTodoByIdEndpoint = new GetChecklistByIdEndpoint();
  private _getChecklistBySerialEndpoint = new GetChecklistBySerialEndpoint();
  private _getChecklistsByPartialSerialEndpoint =
    new GetChecklistsByPartialSerialNumber();
  private _getChecklistsByTimestampEndpoint =
    new GetChecklistsByTimestampEndpoint();
  private _checkSetValueEndpoint = new CheckSetValueEndpoint();
  private _createCommentEndpoint = new CreateCommentEndpoint();
  private _createCommentPhotoEndpoint = new CreateCommentPhotoEndpoint();
  private _getInstructionByIdEndpoint = new GetWorkInstructionByIdEndpoint();
  private _signChecklistEndpoint = new SignChecklistEndpoint();
  private _signUndoChecklistEndpoint = new SignUndoChecklistEndpoint();
  private _createChecklistEndpoint = new CreateChecklistEndpoint();

  private async accessToken() {
    return (await getToken()).content;
  }

  public async createChecklist(
    request: ICreateChecklistRequest,
  ): Promise<IContentResult<ICreateChecklistResponse>> {
    return await this._createChecklistEndpoint
      .authenticate(await this.accessToken())
      .execute(request);
  }

  public async getById(
    uid: string,
    language?: string,
  ): Promise<IContentResult<IChecklistDTO>> {
    if (language) {
      return await this._getTodoByIdEndpoint
        .authenticate(await this.accessToken())
        .execute(uid, { language: language });
    }

    return await this._getTodoByIdEndpoint
      .authenticate(await this.accessToken())
      .execute(uid);
  }

  public async getBySerial(
    serial: string,
  ): Promise<IContentResult<IChecklistDTO>> {
    return await this._getChecklistBySerialEndpoint
      .authenticate(await this.accessToken())
      .execute(serial);
  }

  public async getByTimestamp(
    amount: number,
  ): Promise<IContentResult<IChecklistByTimestampResponse>> {
    // let res: any;

    // try {
    //   res = await this._getChecklistsByTimestampEndpoint
    //     .authenticate(await this.accessToken())
    //     .execute(`${amount}`);
    // } catch (e) {
    //   alert(e);
    // }

    return await this._getChecklistsByTimestampEndpoint
      .authenticate(await this.accessToken())
      .execute(`${amount}`);
  }

  public async getByPartialSerialNumber(
    request: IGetChecklistsByPartialSerialNumberRequest,
  ): Promise<IContentResult<IChecklistsByPartialSerialNumberResponse>> {
    return await this._getChecklistsByPartialSerialEndpoint
      .authenticate(await this.accessToken())
      .execute(undefined, request);
  }

  public async checkSetValue<T>(
    request: ISetCheckValueBaseRequest<T>,
  ): Promise<IContentResult<ISetCheckValueResponse>> {
    return await this._checkSetValueEndpoint
      .authenticate(await this.accessToken())
      .execute(request);
  }

  public async createComment(
    request: ICreateCommentRequest,
  ): Promise<IContentResult<ICreateCommentResponse>> {
    return await this._createCommentEndpoint
      .authenticate(await this.accessToken())
      .execute(request);
  }

  public async createCommentPhoto(
    request: ICreateCommentPhotoRequest,
  ): Promise<IContentResult<ICreatePhotoResponse>> {
    const formData = new FormData();

    formData.append("checklistUid", request.checklistUid);
    formData.append("groupUid", request.groupUid);
    formData.append("checkUid", request.checkUid);
    formData.append("commentUid", request.commentUid);

    for (const photo of request.photos) {
      formData.append("photos", photo);
    }

    return await this._createCommentPhotoEndpoint
      .authenticate(await this.accessToken())
      .execute(formData);
  }

  public async getInstructionById(request: IGetWorkInstructionByIdRequest) {
    return await this._getInstructionByIdEndpoint
      .authenticate(await this.accessToken())
      .execute(request.uid);
  }

  public async signChecklist(request: ISignChecklistRequest) {
    return await this._signChecklistEndpoint
      .authenticate(await this.accessToken())
      .execute(request);
  }

  public async signUndoChecklist(request: ISignChecklistRequest) {
    return await this._signUndoChecklistEndpoint
      .authenticate(await this.accessToken())
      .execute(request);
  }
}
