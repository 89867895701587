import { ChecklistService } from "./infrastructure/services/checklists/ChecklistService";
import { Container } from "inversify";
import { SignalRService } from "./infrastructure/services/signalR/SignalRService";

const myContainer = new Container();

myContainer.bind(SignalRService).toSelf().inSingletonScope();
myContainer.bind(ChecklistService).toSelf().inSingletonScope();

export { myContainer };
