import { mdiLoading } from "@mdi/js";
import Icon from "@mdi/react";
import { format } from "date-fns";
import TimeAgo from "javascript-time-ago";
import {
  CheckCircle2,
  CircleDashed,
  CircleDotDashed,
  CircleSlash,
} from "lucide-react";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import nl from "relative-time-format/locale/nl";
import {
  ICheckBaseViewModel,
  OriginReference,
} from "../../../application/viewModels/CheckBaseViewModel";
import UserAvatar from "../../molecules/user-avatar";
import { Badge } from "../../ui/badge";
import { Button } from "../../ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "../../ui/popover";

export function ActionContainer({ children }: PropsWithChildren) {
  return <div className="mt-5 w-full flex gap-x-3 px-4 pb-4">{children}</div>;
}

export function AnswerContainer({ children }: PropsWithChildren) {
  return <div className="mt-4 1mb-3 w-full">{children}</div>;
}

TimeAgo.addLocale(nl);
const timeAgo = new TimeAgo("nl-NL");

export function AnswerBadge({
  onReset,
  children,
  checklistAccepted,
  lastTouchedBy,
  canDelete = true,
  lastTouchedAt,
  hideAuthor = false,
  deleteLabel = "Verwijderen",
}: PropsWithChildren & {
  onReset: () => void;
  deleteLabel?: string;
  checklistAccepted: boolean;
  canDelete?: boolean;
  lastTouchedBy: OriginReference | null;
  lastTouchedAt: Date | null;
  hideAuthor?: boolean;
}) {
  const { t } = useTranslation();
  const delLabel =
    deleteLabel === "Verwijderen"
      ? t("inspectionPage.checkPopup.card.deleted")
      : deleteLabel;

  return (
    <Badge className="flex flex-col text-base relative rounded-t-none pl-0 pr-0 rounded-b-lg bg-[#cac4d0] w-full">
      <div className="flex items-center w-full pl-4 min-h-11 h-fit leading-none pr-2">
        <img
          style={{
            position: "absolute",
            left: 17,
            top: -14,
            zIndex: 99999,
            width: 20,
            transform: "rotate(0deg)",
          }}
          src="/Polygon 2.svg"
        />
        {children}
        {canDelete && !checklistAccepted && (
          <Button
            onClick={onReset}
            size={"sm"}
            variant={"outline"}
            className="ml-auto 1rounded-lg rounded-full border-2"
          >
            {delLabel}
          </Button>
        )}
      </div>
      {!hideAuthor && (
        <div className="pl-4 border-t mt-1 border-dashed border-border/30 w-full pr-4">
          <div className="h-9 mt-2 gap-x-2 justify-between flex items-center w-full text-primary-foreground">
            <div>
              <Popover>
                <PopoverContent className="px-3 py-2 w-fit">
                  <span className="text-sm">
                    {lastTouchedAt
                      ? format(lastTouchedAt, "dd LLL HH:mm:ss")
                      : ""}
                  </span>
                </PopoverContent>
                <PopoverTrigger asChild>
                  <span className="text-sm">
                    {timeAgo.format(
                      new Date(lastTouchedAt ?? 0),
                      "mini-minute-now",
                    )}
                  </span>
                </PopoverTrigger>
              </Popover>
            </div>
            <UserAvatar left id={lastTouchedBy?.value} />
          </div>
        </div>
      )}
    </Badge>
  );
}

export function PrimaryActionButton({
  children,
  onClick,
  disabled,
}: PropsWithChildren & { onClick: () => void; disabled: boolean }) {
  return (
    <Button
      className="bg-[#cac4d0] rounded-full w-full text-base"
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button>
  );
}

export function SecondaryActionButton({
  children,
  onClick,
  disabled,
}: PropsWithChildren & { onClick: () => void; disabled: boolean }) {
  return (
    <Button
      variant={"outline"}
      className="text-base border border-[#cac4d0] bg-transparent text-[#cac4d0] rounded-full w-full"
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button>
  );
}

export default function CheckStateCard({
  check,
  signed,
  sectionTitle,
  children,
}: PropsWithChildren<{
  signed: boolean;
  check: ICheckBaseViewModel;
  sectionTitle: string;
}>): JSX.Element {
  return (
    <div className="px-3 md:px-6">
      <div
        key={check.uid}
        className={`1bg-[#3c3a41] border-2 1bg-[#333239] 1bg-zinc-700 fade-in duration-200 ease-out 1border flex mb-4 flex-col rounded-xl 1bg-neutral-800 w-full 1pt-5 1pb-4 1px-4 items-start ${
          check.finished
            ? "1border-emerald-500 border-[#cac4d0]"
            : "1border-transparent border-[#cac4d0]"
        }`}
      >
        <div className="flex w-full">
          <div className="flex flex-col">
            <span
              style={{ wordBreak: "break-word" }}
              className={`pt-5 px-4 ${
                check.finished ? "" : ""
              } xl:text-base text-base font-medium opacity text-[#cac4d0] flex items-start mb-1`}
            >
              <div className="hidden xl:block">
                {check.loading && (
                  <Icon
                    className="animate-spin text-muted-foreground mr-2"
                    path={mdiLoading}
                    size={1}
                  />
                )}
                {!check.loading && !check.finished && !check.required && (
                  <div className="mr-2">
                    <CircleDashed
                      className={`w-6 h-6 text-muted-foreground ${
                        signed ? "" : "animate-[spin_7s_linear_infinite]"
                      }`}
                    />
                  </div>
                )}
                {!check.loading && !check.finished && check.required && (
                  <div className="mr-2">
                    <CircleDotDashed className="w-6 h-6 animate-[spin_7s_linear_infinite] text-muted-foreground" />
                  </div>
                )}
                {!check.loading && check.finished && (
                  <div className="mr-2">
                    <CheckCircle2 className="w-6 h-6 text-green-400" />
                  </div>
                )}
              </div>
              {/* TODO: check title should be a prop. */}
              {check.title.includes(":date:")
                ? check.title.split(":date:")[1]
                : check.title.includes(":stempel:")
                ? check.title.split(":stempel:")[1]
                : check.title}
            </span>
          </div>
        </div>

        {signed && !check.required && !check.finished ? (
          <AnswerContainer>
            <AnswerBadge
              lastTouchedBy={check.lastTouchedBy}
              lastTouchedAt={check.lastTouchedAt}
              checklistAccepted={signed}
              onReset={() => check.reset()}
              hideAuthor
            >
              <CircleSlash className="w-5 h-5 mr-2" />
              Niet uitgevoerd
            </AnswerBadge>
          </AnswerContainer>
        ) : (
          children
        )}
      </div>
    </div>
  );
}
