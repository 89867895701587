import { useAuth, useOrganization } from "@clerk/clerk-react";
import type {
  OrganizationMembershipResource,
  UserResource,
} from "@clerk/types";
import { useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../components/ui/dialog";
import { useUserStore } from "../../../stores/project-store";
import { Avatar, AvatarFallback, AvatarImage } from "../../ui/avatar";
import { Input } from "../../ui/input";
import { InputOTP, InputOTPGroup, InputOTPSlot } from "../../ui/input-otp";

type LoginMethods =
  | {
      pincode: { code: string };
    }
  | undefined;

export default function LoginPopup({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  useEffect(() => {
    setDialogOpen(open);

    if (open) {
      setCloseAllowed(true);
    }
  }, [open]);

  const [dialogOpen, setDialogOpen] = useState(open);
  const { userId, signOut } = useAuth();
  const { memberships: membershipList } = useOrganization({
    memberships: {
      pageSize: 500,
    },
  });
  const [wrongPincode, setWrongPincode] = useState(false);
  const [selectedUser, setSelectedUser] =
    useState<OrganizationMembershipResource>();
  const [selectedClerkUser, setSelectedClerkUser] = useState<UserResource>();
  const [searchQuery, setSearchQuery] = useState("");
  const [correctPincode, setCorrectPincode] = useState(false);

  async function getClerkUser(id: string) {
    //@ts-ignore
    const token = await window.Clerk.session.getToken({
      template: "jwt-template-1",
    });

    const user = await fetch(
      `${process.env.REACT_APP_MANAGEMENT_URL}/api/users/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      },
    );

    const data = await user.json();

    return data.user as UserResource;
  }

  const { loggedInUser, updateLoggedInUser } = useUserStore();

  useEffect(() => {
    // Check if admin user is logged in.
    // Then Check if user is logged in using loginMethod.
    if (
      userId === process.env.REACT_APP_ADMIN_USER_ID &&
      loggedInUser === null
    ) {
      setDialogOpen(true);
      setCloseAllowed(false);
    }
  }, [membershipList]);

  const membershipListFiltered = membershipList?.data?.filter(
    (m) =>
      `${m.publicUserData.firstName} ${m.publicUserData.lastName}`
        ?.toLocaleLowerCase()
        .includes(searchQuery.toLocaleLowerCase()) &&
      m.publicUserData.userId !== process.env.REACT_APP_ADMIN_USER_ID,
  );
  const ref = useRef<HTMLInputElement>(null);
  const [pincode, setPincode] = useState("");
  const [closeAllowed, setCloseAllowed] = useState(true);

  function reset() {
    setPincode("");
    setCloseAllowed(true);
    setCorrectPincode(false);
    setWrongPincode(false);
    setSelectedUser(undefined);
    setSearchQuery("");
    setSelectedClerkUser(undefined);
  }

  const loggedInUserFromState = membershipList?.data?.find(
    (m) => m.publicUserData.userId === loggedInUser?.userId,
  );

  function handlesignOut() {
    if (confirm("Weet je zeker dat je uit wilt loggen?")) {
      signOut();
    }
  }

  const pincodeLength =
    (selectedClerkUser?.publicMetadata.loginMethods as LoginMethods)?.pincode
      .code.length ?? 4;

  const pincodeLengthArray = Array.from(Array(pincodeLength).keys());

  return (
    <Dialog
      open={dialogOpen}
      onOpenChange={(incoming) => {
        if (!incoming && !closeAllowed) {
          return;
        } else {
          setDialogOpen(incoming);
          if (!incoming) {
            onClose();
            reset();
          }
        }
      }}
    >
      <DialogContent closedAllowed={closeAllowed} className="sm:max-w-[625px]">
        <DialogHeader>
          <DialogTitle>Gebruiker wisselen</DialogTitle>
          <DialogDescription>
            Wissel van gebruiker door een gebruiker te kiezen en de bijbehorende
            loging methode te gebruiken. Je kan door middel van deze{" "}
            <span
              className="text-blue-400 text-sm cursor-pointer hover:text-blue-500"
              onClick={handlesignOut}
            >
              link
            </span>{" "}
            ook inloggen met je eigen account.
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4 w-full grid-cols-1 sm:grid-cols-2">
          {loggedInUser && (
            <div className="col-span-2 gap-y-2 rounded-md bg-blue-600 px-3 py-2.5 flex items-center sm:flex-row flex-col justify-between">
              <div className="w-full">
                <span className="text-base font-semibold block mb-1">
                  Huidige gebruiker
                </span>
                <div className="flex items-center">
                  <Avatar className="w-5 h-5 mr-2">
                    <AvatarImage
                      src={loggedInUserFromState?.publicUserData?.imageUrl}
                    />
                    <AvatarFallback>VC</AvatarFallback>
                  </Avatar>
                  <span className="text-sm block">
                    {loggedInUserFromState?.publicUserData.firstName}{" "}
                    {loggedInUserFromState?.publicUserData.lastName}
                  </span>
                </div>
              </div>
              <div className="flex items-center gap-x-2 w-full justify-end">
                {/* <div
                  className="py-1.5 px-3 cursor-pointer text-sm rounded-md border-transparent border font-medium duration-150 hover:bg-blue-700"
                  onClick={() => {
                    handleUpdatePin();
                  }}
                >
                  Pincode wijzigen
                </div> */}
                <div
                  className="py-1.5 px-3 cursor-pointer text-sm rounded-md bg-blue-800 border duration-150 hover:bg-blue-900 border-blue-800 font-medium"
                  onClick={() => {
                    updateLoggedInUser(null);
                    // if (selectedClerkUser) {
                    //   setTimeout(() => {
                    //     ref.current?.focus();
                    //   }, 0);
                    // }
                  }}
                >
                  Uitloggen
                </div>
              </div>
            </div>
          )}
          <div>
            <Input
              className="mb-2 bg-secondary"
              placeholder="Gebruikers zoeken..."
              onChange={(e) => setSearchQuery(e.target.value)}
            />

            <div className="rounded-md border h-64 overflow-y-auto">
              {membershipListFiltered?.map((member) => {
                return (
                  <div
                    onClick={async () => {
                      setWrongPincode(false);
                      setCorrectPincode(false);
                      setPincode("");

                      const user = membershipList?.data?.find(
                        (m) => m.id === member.id,
                      );

                      if (!user) return;

                      setSelectedUser(user);

                      const clerkUserData = await getClerkUser(
                        user.publicUserData.userId ?? "",
                      );

                      setSelectedClerkUser(clerkUserData);

                      // Without timeout it won't focus since the disabled attribute is true (not sure why this happens). So keep this timeout even though it's set to 0ms.
                      setTimeout(() => {
                        ref.current?.focus();
                      }, 0);
                    }}
                    key={member.id}
                    className={`h-9 flex items-center cursor-pointer w-full duration-150 px-3 ${
                      selectedUser && selectedUser.id === member.id
                        ? "bg-primary/10"
                        : "hover:bg-primary/5"
                    }`}
                  >
                    <Avatar className="w-5 h-5 mr-2">
                      <AvatarImage src={member.publicUserData?.imageUrl} />
                      <AvatarFallback>VC</AvatarFallback>
                    </Avatar>
                    <span className="text-sm">
                      {member.publicUserData.firstName}{" "}
                      {member.publicUserData.lastName}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="rounded-md border pt-3 h-full relative">
            <span className="absolute bg-background px-2 top-[-10px] left-2 text-sm">
              Pincode
            </span>
            <div className="p-3 flex items-center flex-col justify-start h-full relative">
              <span
                className={`${
                  correctPincode ? "text-green-400" : "text-red-400"
                } block text-sm h-8 text-center absolute top-[24px]`}
              >
                {wrongPincode && "Pincode incorrect."}
                {correctPincode && "Pincode correct."}
              </span>
              <div className="pt-[64px]">
                <InputOTP
                  autoFocus
                  ref={ref}
                  onChange={(value) => {
                    setPincode(value);

                    if (value.length === pincodeLength) {
                      if (
                        value ===
                        (
                          selectedClerkUser?.publicMetadata
                            ?.loginMethods as LoginMethods
                        )?.pincode?.code
                      ) {
                        //..
                        updateLoggedInUser({
                          userId: selectedClerkUser?.id ?? "",
                        });
                        setCorrectPincode(true);
                        setTimeout(() => {
                          setDialogOpen(false);
                          reset();
                          onClose();
                        }, 500);
                      } else {
                        setWrongPincode(true);
                      }
                    } else {
                      setWrongPincode(false);
                    }
                  }}
                  value={pincode}
                  maxLength={999}
                  disabled={!selectedClerkUser}
                >
                  <InputOTPGroup>
                    {selectedClerkUser ? (
                      <>
                        {pincodeLengthArray.map((x) => (
                          <InputOTPSlot
                            key={`otpslot-${x}`}
                            className={`bg-secondary/25`}
                            index={x}
                          />
                        ))}
                      </>
                    ) : (
                      <>
                        <InputOTPSlot className={`bg-secondary/25`} index={0} />
                        <InputOTPSlot className={`bg-secondary/25`} index={1} />
                        <InputOTPSlot className={`bg-secondary/25`} index={2} />
                        <InputOTPSlot className={`bg-secondary/25`} index={3} />
                      </>
                    )}
                  </InputOTPGroup>
                </InputOTP>
              </div>
            </div>
          </div>
        </div>
        <DialogFooter>
          <span
            className="text-blue-400 text-sm cursor-pointer hover:text-blue-500"
            onClick={handlesignOut}
          >
            Inloggen met je eigen account?
          </span>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
